<template>
  <div class="page">
    <!-- <top-bar :title="'查看评价'"></top-bar> -->
    <!-- <div class="line"></div> -->
    <div class="content">
      <div class="tit">救助详情</div>
      <van-cell-group>
        <van-cell title="核对人" :value="appJqSalvation.knotUser" />
        <!-- <van-cell title="核对人联系方式" :value="appJqSalvation.knotUser" /> -->
        <van-cell title="救助时间" :value="appJqSalvation.salvationTime || '未知'" />
        <van-cell title="救助描述" :label="appJqSalvation.salvationDesc" />
      </van-cell-group>
      <div class="img">
        <van-uploader v-model="fileList" :multiple="false" :deletable="false" :show-upload="false" />
      </div>
      <div class="line"></div>
      <div class="tit">评价</div>
      <div class="evaluate">
        <div class="top">
          <van-rate v-model="evaluateVal" size="28" color="#f8ae0f" />
          <div class="text">{{ evaluateText[evaluateVal] }}</div>
        </div>
        <p class="detai">
          <van-field style="background:#f5f5f5" v-model="evaluateDesc" rows="2" autosize type="textarea" placeholder="请输入评价" />
        </p>
      </div>
    </div>
    <div class="btn">
      <van-button round block type="info" native-type="submit" @click="evaluateFun">提交评价</van-button>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
// import { upload, getDictTree } from '@/utils/common'
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  data() {
    return {
      appJqSalvation: {},
      evaluateVal: 5,
      fileList: [],
      evaluateDesc: "",
      evaluateText: {
        1: "十分不满意",
        2: "不满意",
        3: "一般",
        4: "比较满意",
        5: "十分满意",
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDataList();
  },
  beforeDestroy() { },
  methods: {
    getDataList() {
      this.$httpApp({
        url: this.$httpApp.adornUrl(
          `/wxapp/app/appjqsalvation/infoCheck/${this.id}`
        ),
        method: "get",
        params: this.$httpApp.adornParams(),
      }).then(({ data }) => {
        if (data.code == 0) {
          console.log(data);
          this.appJqSalvation = data.appJqSalvation;
          if (data.appJqSalvation.fileMessages) {
            data.appJqSalvation.fileMessages.map((item) => {
              this.fileList.push({ url: item.objectUrl });
            });
          }
        }
      });
    },
    evaluateFun() {
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appjqsalvation/saveEvaluate"),
        method: "post",
        data: this.$httpApp.adornData({
          id: this.appJqSalvation.id,
          evaluate: this.evaluateVal,
          evaluateDesc: this.evaluateDesc
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          Toast.success("评价成功");
          setTimeout(() => {
            this.$router.push({ name: 'rescue-list' });
          }, 500);
        } else {
          Toast.success(data.msg);
        }
      });
    },
  },

  components: {
    topBar,
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding: 0 0 120px 0;
  min-height: 100%;
  background: #fff;
  .content {
    font-size: 24px;
    .tit {
      padding: 30px 0 30px 40px;
      position: relative;
      color: #000;
      font-weight: bold;
      font-size: 26px;
      //   border-bottom: 1px solid #eee;
      &::before {
        content: "";
        width: 4px;
        height: 26px;
        background: #017afd;
        position: absolute;
        left: 30px;
        top: 30px;
      }
    }
    .img {
      padding: 20px 30px;
    }
    .evaluate {
      border-top: 1px solid #eee;
      padding: 20px 80px;
      box-sizing: border-box;
      .top {
        text-align: center;
        .text {
          margin-top: 10px;
          color: #999;
        }
      }
      .detai {
        color: #999;
        line-height: 30px;
        margin-top: 30px;
      }
    }
  }
}
.line {
  height: 20px;
  width: 100%;
  background: #f5f5f5;
}
.btn {
  width: 90%;
  position: fixed;
  bottom: 0;
  left: 5%;
  height: 100px;
  z-index: 9999;
}
</style>
